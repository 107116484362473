function val(param) {
    if (typeof (param) === 'function') {
        return param.call(this);
    }
    return param;
}

var fontSizeBounds = {
    min: 4,
    max: 100
};
var fontChoices = {
    'font-size': {
        '10px': 10,
        '11px': 11,
        '12px': 12,
        '13px': 13,
        '14px': 14,
        '16px': 16,
        '18px': 18,
        '20px': 20,
        '24px': 24,
        '28px': 28,
        '32px': 32,
        '36px': 36
    },
    'font-family': { // Font-Family from TinyMCE v4 documentation
        'andale mono,times': 'Andale Mono',
        'arial,helvetica,sans-serif': 'Arial',
        'arial black,avant garde': 'Arial Black',
        'book antiqua,palatino': 'Book Antiqua',
        'comic sans ms,sans-serif': 'Comic Sans MS',
        'courier new,courier': 'Courier New',
        'georgia,palatino': 'Georgia',
        'helvetica': 'Helvetica',
        'impact,chicago': 'Impact',
        'tahoma,arial,helvetica,sans-serif': 'Tahoma',
        'terminal,monaco': 'Terminal',
        'times new roman,times': 'Times New Roman',
        'trebuchet ms,geneva': 'Trebuchet MS',
        'verdana,geneva': 'Verdana'
    }
};

var options = {
    brand: '',
    tabs: [],
    commands: {},
    solutionDropdown: undefined,
    saveCommand: undefined,
    tabCommands: [],
    identity: {
        name: 'Unknown',
        image: ''
    },
    notifications: false,
    emptyNotificationsMessage: 'No notification',
    notificationsLength: 7
};

function sortSolutions(solA,solB) {
    if(solA.rank && solB.rank) {
        return solA.rank - solB.rank;
    }
    if(!solA.rank && solB.rank) {
        return 1;
    }
    if(solA.rank && !solB.rank) {
        return -1;
    }
    return 0;
}

/* eslint complexity:off */
class Ribbon {

    persistentNotifications = [];
    transientNotifications = [];

    constructor(element, params) {
        this.$ribbonBar = $(element);
        this.$ribbonBar.addClass('ribbon-bar');
        $.extend(options, params);

        this.$ribbonBar.append('<div class="header"></div>');
        this.$ribbonBar.append('<div class="tabs"><ul></ul><div class="tab-commands"></div></div>');
        var $header = this.$ribbonBar.find('.header').append('<div class="brand">' + options.brand + '</div><div class="divider"></div>');
        $header.append('<div class="solution-dropdown command"></div><div class="divider"></div>');
        $('<div id="solution-dropdown"></div>').appendTo($header);

        if (options.saveCommand && options.commands[options.saveCommand]) {
            $header.append('<div class="quick-save command click" data-command="' + options.saveCommand + '"></div>');
        }

        $header.append('<div class="current-file"></div>');
        $header.append('<div class="look"></div>');
        if(options.notifications) {
            $header.append(
                '<div class="notification-dropdown command">' +
                    '<div class="notification-button"><i class="fa fa-bell-o dropdown-toggle" data-toggle="dropdown"></i></div>' +
                    '<div class="dropdown-content hidden"></div>' +
                '</div>');

            this.loadPersistentNotifications();
            this.refreshNotifications();
            // Add a listener for other windows changing the persistence
            window.addEventListener('storage', event => {
                if (event.key === 'notifications') {
                    this.loadPersistentNotifications();
                    this.refreshNotifications();
                }
            });
        }

        var fileTabIdx = null;
        var dropdownActions = {};
        var dynamicDropdownActions = {};
        var requireColorDropdown = false;

        if (options.tabs.length === 0) {
            this.$ribbonBar.find('.tabs').hide();
        }

        if(options.solutionDropdown) {
            options.solutionDropdown = options.solutionDropdown.sort(sortSolutions);
        }

        //Create Tabs
        for (var tabIdx = 0; tabIdx < options.tabs.length; tabIdx++) {
            var tab = options.tabs[tabIdx];
            if (!tab.id) {
                tab.id = '' + (Math.random() * 1000000);
            }
            this.$ribbonBar.find('.tabs ul').append('<li class="tab" data-tab="' + tab.id + '"></li>');

            if (tab.id === 'file') {
                fileTabIdx = tabIdx;
                continue;
            }

            var $ribbon = $('<div class="ribbon" data-tab="' + tab.id + '"></div>').appendTo(this.$ribbonBar);

            //Create Groups within tab
            if (tab.commandGroups && tab.commandGroups.length > 0) {
                for (var grpIdx = 0; grpIdx < tab.commandGroups.length; grpIdx++) {
                    var group = tab.commandGroups[grpIdx];
                    if (!group.id) {
                        group.id = '' + (Math.random() * 1000000);
                    }

                    var $group = $('<div class="group" data-group="' + group.id + '"><div class="group-label"></div></div>').appendTo($ribbon);

                    //Create Layouts inside groups
                    if (group.commandLayouts && group.commandLayouts.length > 0) {
                        for (var layoutIdx = 0; layoutIdx < group.commandLayouts.length; layoutIdx++) {
                            var layout = group.commandLayouts[layoutIdx];

                            //Create the command buttons inside the layouts
                            if (!layout.commands || layout.commands.length <= 0) {
                                layout.commandDefinitions = [];
                                continue;
                            }

                            for (var commandIdx = 0; commandIdx < layout.commands.length; commandIdx++) {
                                var command = layout.commands[commandIdx];

                                if (command in options.commands) {
                                    switch (options.commands[command].type) {
                                        case 'dropdown':
                                            dropdownActions[command] = options.commands[command];
                                            break;

                                        // add subtypes to specialized the input-dropdown component
                                        case 'font-size':
                                        case 'font-family':
                                            var fontCommand = options.commands[command];
                                            var subtype = fontCommand.type;

                                            fontCommand.type = 'input-dropdown';
                                            fontCommand.subtype = subtype;
                                            fontCommand.choices = fontChoices[subtype];

                                            if (fontCommand.fontSize && typeof fontCommand.fontSize === 'function') {
                                                fontCommand.input = fontCommand.fontSize;
                                            }

                                            if (fontCommand.fontFamily && typeof fontCommand.fontFamily === 'function') {
                                                fontCommand.input = fontCommand.fontFamily;
                                            }

                                            dynamicDropdownActions[command] = fontCommand;
                                            break;

                                        case 'input-dropdown':
                                        case 'dynamic-dropdown':
                                            dynamicDropdownActions[command] = options.commands[command];
                                            break;
                                        case 'color':
                                            if (!requireColorDropdown) {
                                                requireColorDropdown = true;
                                            }
                                            break;
                                    }
                                }

                                var commandHTML = this._commandFactory(command);
                                var $layout = this._getLayoutContainer(layout, $group, commandIdx);

                                if (commandHTML && $layout) {
                                    $layout.append(commandHTML);
                                }
                            }
                        }

                    } else {
                        group.visible = false;
                        group.commandLayouts = [];
                    }
                }
            } else {
                tab.commandGroups = [];
                tab.visible = false;
            }
        }

        //Create Dropdown Actions Structure
        for (var commandId in dropdownActions) {
            command = dropdownActions[commandId];
            if (command.commands) {
                var $widget = $('<div class="dropdown-container layout small" data-command="' + commandId + '"></div>').appendTo(this.$ribbonBar);
                for (var subCommandIdx = 0; subCommandIdx < command.commands.length; subCommandIdx++) {
                    commandHTML = this._commandFactory(command.commands[subCommandIdx]);
                    if (commandHTML !== false) {
                        $widget.append(commandHTML);
                    }
                }
            } else if (command.widget) {
                $widget = $('<div class="dropdown-container" data-command="' + commandId + '"></div>').appendTo(this.$ribbonBar);
                $widget.append(val(command.widget));
            } else {
                command.visible = false;
            }
        }

        // Create Input-Dropdown and DynamciDropdown Structure
        for (var dynamicDropdownCommandId in dynamicDropdownActions) {
            command = dynamicDropdownActions[dynamicDropdownCommandId];
            var $inputDropdown = $('<div/>', { class: 'dynamic-dropdown-container', 'data-command': dynamicDropdownCommandId });

            if (command.choices) {
                let choices = typeof command.choices === 'function' ? command.choices() : command.choices;

                $inputDropdown.addClass('layout small').appendTo(this.$ribbonBar);
                var choicesHTML = this._choicesFactory(dynamicDropdownCommandId, choices);

                for (var i = 0; i < choicesHTML.length; i++) {
                    $inputDropdown.append(choicesHTML[i]);
                }
            } else if (command.commands) {
                $inputDropdown.addClass('layout small').appendTo(this.$ribbonBar);

                for (var ci = 0; ci < command.commands.length; ci++) {
                    var commandHint = command.commands[ci];
                    var inputDropdownCommandHTML = this._commandFactory(commandHint);

                    if (inputDropdownCommandHTML) {
                        $inputDropdown.append(inputDropdownCommandHTML);
                    }
                }
            } else if (command.widget) {
                $inputDropdown.append(command.widget).appendTo(this.$ribbonBar);
            } else {
                command.visible = false;
            }
        }

        //Create Color Picker Structure
        if (requireColorDropdown) {
            $widget = $('<div class="dropdown-colorpicker"></div>').appendTo(this.$ribbonBar);
            var colors = ['000000', '993300', '333300', '003300', '003366', '000080', '333399', '333333', '800000', 'FF6600', '808000', '008000', '008080', '0000FF', '666699', '808080', 'FF0000', 'FF9900', '99CC00', '339966', '33CCCC', '3366FF', '800080', '969696', 'FF00FF', 'FFCC00', 'FFFF00', '00FF00', '00FFFF', '00CCFF', '993366', 'C0C0C0', 'FF99CC', 'FFCC99', 'FFFF99', 'CCFFCC', 'CCFFFF', '99CCFF', 'CC99FF', 'FFFFFF'];
            for (i = 0; i < colors.length; i++) {
                $widget.append('<div class="color" data-color=' + colors[i] + ' style="background-color:#' + colors[i] + '"></div>');
            }
        }

        dropdownActions = {};
        //Create the File Special Menu
        if (fileTabIdx !== null) {
            this.$ribbonBar.append('<div id="file-pane"><div id="file-pane-left"><div class="back"></div><div id="file-pane-identity"><image id="file-pane-identity-image"></image><div id="file-pane-identity-name"></div></div><div id="file-commands" class="layout small"></div></div><div id="file-pane-right"></div></div>');
            var $commands = this.$ribbonBar.find('#file-commands');
            var $rightPane = this.$ribbonBar.find('#file-pane-right');
            tab = options.tabs[fileTabIdx];
            if (tab.commandGroups && tab.commandGroups.length > 0) {
                for (grpIdx = 0; grpIdx < tab.commandGroups.length; grpIdx++) {
                    group = tab.commandGroups[grpIdx];
                    if (!group.id) {
                        group.id = '' + (Math.random() * 1000000);
                    }
                    if (group.commandLayouts && group.commandLayouts.length > 0) {
                        for (layoutIdx = 0; layoutIdx < group.commandLayouts.length; layoutIdx++) {
                            layout = group.commandLayouts[layoutIdx];

                            //Create the command buttons inside the layouts
                            if (layout.commands && layout.commands.length > 0) {
                                for (commandIdx = 0; commandIdx < layout.commands.length; commandIdx++) {
                                    command = layout.commands[commandIdx];
                                    if (command in options.commands && options.commands[command].type === 'dropdown') {
                                        dropdownActions[command] = options.commands[command];
                                    }
                                    commandHTML = this._commandFactory(command);
                                    if (commandHTML !== false) {
                                        $commands.append(commandHTML);
                                    }
                                }
                            } else {
                                layout.commandDefinitions = [];
                            }

                        }

                    }
                }
            }
            this.$ribbonBar.find('.back').click(() => {
                this.closeFilePane();
            });
        }

        $rightPane = this.$ribbonBar.find('#file-pane-right');
        for (commandId in dropdownActions) {
            command = dropdownActions[commandId];

            if (command.widget) {
                $('<div class="file-pane-right-dropdown" data-command="' + commandId + '"></div>').appendTo($rightPane).html(val(command.widget));
            }
        }

        //Create the tabs commands
        for (var idx = 0; idx < options.tabCommands.length; idx++) {
            command = options.tabCommands[idx];
            commandHTML = this._commandFactory(command);
            if (commandHTML !== false) {
                this.$ribbonBar.find('.tab-commands').append(commandHTML);
            }
        }

        this.refresh();

        //Sets the default tab active
        this.activateTab();

        var that = this;

        //Add click handlers on tabs
        this.$ribbonBar.find('.tabs ul li').click(function(event) {
            var $tab = $(this);
            if ($tab.is('.active')) {
                that.$ribbonBar.find('.ribbon[data-tab="' + $tab.data('tab') + '"]').addClass('hidden');
                $tab.removeClass('active');
                that.$ribbonBar.trigger('resize', that.$ribbonBar.outerHeight());
            } else {
                that.activateTab($tab.data('tab'));
            }
        });

        //File Pane commands need to properly handle the active state of the commands
        this.$ribbonBar.find('#file-pane .command').click(function(event) {
            var $command = $(this);
            if ($command.hasClass('disabled')) {
                return;
            }
            $command.siblings('.active').removeClass('active');
            that.$ribbonBar.find('#file-pane-right .file-pane-right-dropdown').hide();
        });


        //Add click handlers on click commands
        this.$ribbonBar.find('.command.click').click(function(event) {
            var $command = $(this);
            if ($command.hasClass('disabled')) {
                return;
            }
            var commandId = $command.data('command');
            var command = options.commands[commandId];
            if (command) {
                if (typeof (command.callback) === 'function') {
                    $command.addClass('running');
                    try {
                        command.callback.apply(this, arguments);
                    } finally {
                        $command.removeClass('running');
                    }
                    that.refreshCommand(commandId);
                }
            }
        });

        //Add click handlers on click commands
        this.$ribbonBar.find('.command.text-input .image').click(function(event) {
            var $command = $(this).closest('.command');
            if ($command.hasClass('disabled')) {
                return;
            }
            var commandId = $command.data('command');
            var command = options.commands[commandId];
            if (command && $command.find('input').val().trim()) {
                if (typeof (command.callback) === 'function') {
                    $command.addClass('running');
                    try {
                        command.callback.apply(this, [$command.find('input').val().trim()]);
                    } finally {
                        $command.removeClass('running');
                        $command.find('input').val('');
                    }
                    that.refreshCommand(commandId);
                }
            }
        });


        //Add click handlers on toggle commands
        this.$ribbonBar.find('.command.toggle').click(function(event) {
            var $command = $(this);
            if ($command.hasClass('disabled')) {
                return;
            }
            var commandId = $command.data('command');
            var command = options.commands[commandId];
            if (command) {
                var newValue = val(command.toggled);
                if (typeof (command.toggled) === 'boolean' || typeof (command.toggled) === 'undefined') {
                    command.toggled = !command.toggled;
                }

                if (typeof (command.callback) === 'function') {
                    var args = [!newValue];
                    args.push(arguments);
                    command.callback.apply(this, args);
                }

                that.refreshCommand(commandId);
            }
        });

        //Add click handlers on File Pane commands
        this.$ribbonBar.find('#file-pane .command.dropdown').click(function(event, param1, param2, param3) {
            var $command = $(this);
            if ($command.hasClass('disabled')) {
                return;
            }
            var commandId = $command.data('command');
            var command = options.commands[commandId];
            if (command) {
                $command.addClass('active');
                that.$ribbonBar.find('#file-pane-right .file-pane-right-dropdown[data-command="' + commandId + '"]').show();

                if (typeof (command.callback) === 'function') {
                    command.callback.apply(this, arguments);
                }

                that.refreshCommand(commandId);
            }
        });

        // add click handlers on ribbon input-dropdown commands
        this.$ribbonBar.find('.ribbon .command.input-dropdown button').click(function(e) {
            var $command = $(this).closest('.command');

            if ($command.hasClass('disabled')) {
                return;
            }

            var id = $command.data('command');
            var command = options.commands[id];
            var $widget = null;

            if (command && command.type === 'input-dropdown') {
                $widget = that.$ribbonBar.find('.dynamic-dropdown-container[data-command="' + id + '"]');
            }

            var closeCommandDropdown = () => {
                $('body').unbind('click', closeCommandDropdown);
                $widget.unbind('mouseleave', closeCommandDropdown).hide();
                $command.removeClass('active');
                that.refreshCommand(id);
            };

            e.stopPropagation(); //Prevent immediate bubbling that would trigger the body click handler
            $('body').click().bind('click', closeCommandDropdown); //The clicking first make sure that other dropdown closeDropdown() are called if they were still registered
            $widget.bind('mouseleave', closeCommandDropdown);

            // each command has only one wrapper for its component
            var $group = $command.find('div:first-child');
            var groupPosition = $group.offset();
            var dropdownMargin = 2;

            // places the dropdown just under the input with the same width
            var top = groupPosition.top + $group.height() + dropdownMargin;
            var left = groupPosition.left;

            if (left + $widget.outerWidth() > window.innerWidth) { //If it would overflow on the right
                left = left - (left + $widget.outerWidth() - window.innerWidth);
            }

            if (left < 0) { //If it would overflow on the left
                left = 0;
            }
            $widget.css({
                top: top,
                left: left,
                width: $group.width(),
                display: 'inline-block'
            });

            that.refreshCommand(id);
        });

        // add change handlers on input-dropdown inputs
        this.$ribbonBar.find('.ribbon .command.input-dropdown input').change(function(e) {
            var $input = $(this);
            var $command = $input.closest('.command');

            if ($command.hasClass('disabled')) {
                return;
            }

            var id = $command.data('command');
            var command = options.commands[id];

            if (command && command.type === 'input-dropdown') {
                that.refreshInput($input, command, true);
            }
        });

        //Add click handlers on Ribbon Dropdown commands
        this.$ribbonBar.find('.ribbon .command.dropdown, .ribbon .command.color, .ribbon .command.dynamic-dropdown').click(function(event) {
            var $command = $(this).closest('.command');
            if ($command.hasClass('disabled')) {
                return;
            }

            var commandId = $command.data('command');
            var command = options.commands[commandId];
            if (command) {
                if (command.type === 'dynamic-dropdown') {
                    let hasChoices = command.choices() ? true : false;
                    if (Array.isArray(command.choices())) {
                        hasChoices = command.choices().length > 0;
                    } else if (hasChoices) {
                        hasChoices = Object.keys(command.choices()).length > 0;
                    }
                    if (!hasChoices)  {
                        $command.addClass('running');
                        try {
                            command.callback();
                        } finally {
                            $command.removeClass('running');
                        }
                        that.refreshCommand(commandId);
                        return;
                    }
                }

                var $widget = null;

                if (command.type === 'dropdown') {
                    $widget = that.$ribbonBar.find('.dropdown-container[data-command="' + commandId + '"]');
                } else if (command.type === 'dynamic-dropdown') {
                    $widget = that.$ribbonBar.find('.dynamic-dropdown-container[data-command="' + commandId + '"]');
                } else if (command.type === 'color') {
                    $widget = that.$ribbonBar.find('.dropdown-colorpicker');
                }

                var closeCommandDropdown = function (event) {
                    $('body').unbind('click', closeCommandDropdown);
                    $widget.unbind('mouseleave', closeCommandDropdown).hide();
                    $command.removeClass('active');
                };

                event.stopPropagation(); //Prevent immediate bubbling that would trigger the body click handler

                $('body').click().bind('click', closeCommandDropdown); //The clicking first make sure that other dropdown closeDropdown() are called if they were still registered
                $widget.bind('mouseleave', closeCommandDropdown);

                $command.addClass('active');

                var commandPosition = $command.offset();
                var ribbonPosition = that.$ribbonBar.offset();
                var top = commandPosition.top + $command.outerHeight() - ribbonPosition.top;
                var left = commandPosition.left + ($command.outerWidth() / 2) - ($widget.outerWidth() / 2) - ribbonPosition.left;
                if (left + $widget.outerWidth() > window.innerWidth) { //If it would overflow on the right
                    left = left - (left + $widget.outerWidth() - window.innerWidth);
                }
                if (left < 0) { //If it would overflow on the left
                    left = 0;
                }
                var height = $('body').height() - top - 20;

                $widget.css({ top: top, left: left, 'max-height': height });
                $widget.css('display', 'inline-block');

                that.refreshCommand(commandId);

            }
        });

        //Bind color picker color selection
        this.$ribbonBar.find('.dropdown-colorpicker .color').click(function(event) {
            var commandId = that.$ribbonBar.find('.command.active').data('command');
            if (commandId && commandId in options.commands) {
                var command = options.commands[commandId];
                if (typeof (command.callback) === 'function') {
                    var args = ['#' + $(event.target).data('color')];
                    args.push(arguments);
                    command.callback.apply(this, args);
                }
                that.refreshCommand(commandId);
            }
        });

        //Add click handlers on file commands
        this.$ribbonBar.find('.command.file').click(function (event) {
            var $command = $(this);
            if ($(event.target).is('input')) {
                //The faked click inside the input retrigger this listener by natural bubbling
                event.stopPropagation();
                return;
            }
            if ($command.hasClass('disabled')) {
                return;
            }
            var commandId = $command.data('command');
            var command = options.commands[commandId];
            if (command) {
                if (typeof (command.callback) === 'function') {
                    $command.find('input[type=file]').click();
                }
            }
        });
        this.$ribbonBar.find('.command.file input[type=file]').change(function(event) {
            if (this.files && this.files.length > 0) {
                var $command = $(this).closest('.command');
                if ($command.hasClass('disabled')) {
                    return;
                }
                var commandId = $command.data('command');
                var command = options.commands[commandId];
                var $form = $(this).closest('form');
                var args = [this.files, function () { $form.trigger('reset'); }];
                args.push(arguments);
                command.callback.apply(this, args);
                that.refreshCommand(commandId);
            }
        });

        //Bind events for text-input commands
        this.$ribbonBar.find('.command.text-input input').keydown(function (e) {
            var $this =$(this);
            if (e.keyCode === 13) { // Enter
                if ($this.val().trim()) {
                    var $command = $(this).closest('.command');
                    var commandId = $command.data('command');
                    var command = options.commands[commandId];
                    if(command.callback) {
                        command.callback.apply(this, [$this.val().trim()]);
                        $this.val("");
                    }
                }
            } else if (e.keyCode === 27) { // Esc
                $(this).val("").blur();
            }
        });


        //On the file pane, if it is not a dropdown command, after its original handler, we close the file pane
        this.$ribbonBar.find('#file-pane .command').click(function(event) {
            if (options.commands[$(this).data('command')].type !== 'dropdown') {
                that.closeFilePane();
            }
        });

        this.$ribbonBar.find('.solution-dropdown').click(function (event) {
            var $widget = $('#solution-dropdown').empty();

            if ($widget.is(':visible')) {
                $(this).removeClass('active');
                $widget.hide();
                return;
            }
            //Generate the dropdown content
            let currentRank = undefined;
            for (var i = 0; i < options.solutionDropdown.length; i++) {
                var solution = options.solutionDropdown[i];
                if(currentRank && solution.rank && (Math.floor(currentRank / 10000) !== Math.floor(solution.rank/ 10000))) {
                    // Insert a divider
                    $('<hr/>').appendTo($widget);                    
                }
                currentRank = solution.rank;
                var $solution = $('<div class="solution" data-solution="' + val(solution.id) + '"></div>').appendTo($widget);
                if (solution.description) {
                    $solution.attr('title', val(solution.description));
                }
                if (solution.imageURL) {
                    $solution.css('background-image', 'url("' + solution.imageURL + '")');
                }
            }
            $widget.find('.solution').click(function () {
                for (var i = 0; i < options.solutionDropdown.length; i++) {
                    if (options.solutionDropdown[i].id === $(this).data('solution')) {
                        if (typeof (options.solutionDropdown[i].callback) === 'function') {
                            options.solutionDropdown[i].callback.apply(this, arguments);
                        } else {
                            window.open(val(options.solutionDropdown[i].callback));
                        }
                    }
                }
            });

            //Display the control
            $widget.fadeIn(100);

            var $command = $(this).addClass('active');
            var closeDropdown = function (event) {
                $('body').unbind('click', closeDropdown);
                $widget.unbind('mouseleave', closeDropdown).hide();
                $command.removeClass('active');
            };
            event.stopPropagation(); //Prevent immediate bubbling that would trigger the body click handler
            $('body').click().bind('click', closeDropdown); //The clicking first make sure that other dropdown closeDropdown() are called if they were still registered
            $widget.bind('mouseleave', closeDropdown);

            $command.addClass('active');
        });

        this.$ribbonBar.find('.notification-dropdown').click(function() {
            var $dropdownContent = $(this).find('.dropdown-content');
            if($dropdownContent.hasClass('hidden')) {
                $dropdownContent.removeClass('hidden');
                $dropdownContent.fadeIn(100);
                var closeDropdown = function (event) {
                    $('body').unbind('click', closeDropdown);
                    $dropdownContent.unbind('mouseleave', closeDropdown).hide();
                    $dropdownContent.addClass('hidden');
                    $(this).removeClass('active');
                }.bind(this);
                event.stopPropagation(); //Prevent immediate bubbling that would trigger the body click handler
                $('body').click().bind('click', closeDropdown); //The clicking first make sure that other dropdown closeDropdown() are called if they were still registered
                $dropdownContent.bind('mouseleave', closeDropdown);
                $(this).addClass('active');
            } else {
                $dropdownContent.addClass('hidden');
                $(this).removeClass('active');
            }
        });

        this._smallLayoutCommandsCount = 0;
        this._$currentLayout = null;
    }

    refresh() {
        this.refreshSolutionDropdown();

        if (options.saveCommand) {
            this.refreshCommand(options.saveCommand);
        }

        this.refreshTabCommands();

        for (var idx = 0; idx < options.tabs.length; idx++) {
            this.refreshTab(options.tabs[idx]);
        }
        this.activateAtleastOneTab();
    }

    activateAtleastOneTab() {
        var hasActiveTab = false;
        var defaultTab;
        for (var idx = 0; idx < options.tabs.length; idx++) {
            var $tabNode = this.$ribbonBar.find('.tabs ul li[data-tab="' + options.tabs[idx].id + '"]');
            if (!defaultTab && options.tabs[idx].id !== "file") {
                defaultTab = options.tabs[idx];
            }
            if ($tabNode.hasClass("active")) {
                hasActiveTab = true;
                return;
            }
        }
        if (!hasActiveTab && defaultTab) {
            this.activateTab(defaultTab);
        }
    }

    refreshTabCommands() {
        for (var idx = 0; idx < options.tabCommands.length; idx++) {
            this.refreshCommand(options.tabCommands[idx]);
        }
    }

    refreshTab(tab) {
        if (typeof (tab) !== 'object') {
            for (var idx = 0; idx < options.tabs.length; idx++) {
                if (options.tabs[idx].id === tab) {
                    tab = options.tabs[idx];
                    break;
                }
            }
        }
        if (tab) {
            var $tabNode = this.$ribbonBar.find('.tabs ul li[data-tab="' + tab.id + '"]');
            $tabNode.html(val(tab.name));
            $tabNode.attr('title', val(tab.description));

            var visibleGroups = false;
            if (tab.commandGroups) {
                for (var groupIdx = 0; groupIdx < tab.commandGroups.length; groupIdx++) {
                    visibleGroups = this.refreshGroup(tab.commandGroups[groupIdx]) || visibleGroups;
                }
            }
            if (val(tab.visible) === false || visibleGroups === false) {
                $tabNode.addClass('hidden');
                if ($tabNode.hasClass("active")) {
                    this.activateTab();
                }
            } else {
                $tabNode.removeClass('hidden');
            }

            if (tab.id === 'file') {
                $('#file-pane-identity-image').attr('src', val(options.identity.image));
                $('#file-pane-identity-name').html(val(options.identity.name));
            }
        }
    }

    refreshGroup(group) {
        if (typeof (group) !== 'object') {
            if (options.tabs) {
                for (var tabIdx = 0; tabIdx < options.tabs.length; tabIdx++) {
                    if (options.tabs[tabIdx].commandGroups) {
                        for (var groupIdx = 0; groupIdx < options.tabs[tabIdx].commandGroups.length; groupIdx++) {
                            if (options.tabs[tabIdx].commandGroups[groupIdx].id === group) {
                                group = options.tabs[tabIdx].commandGroups[groupIdx];
                                break;
                            }
                        }
                    }
                }
            }
        }
        if (typeof (group) === 'object') { //Validate that an actual group exists (we could call this method with a string and not find that group)
            var $groupNode = this.$ribbonBar.find('.group[data-group="' + group.id + '"]');
            $groupNode.find('.group-label').html(val(group.name));
            $groupNode.attr('title', val(group.description));

            var visibleCommands = false;
            for (var layoutIdx = 0; layoutIdx < group.commandLayouts.length; layoutIdx++) {
                var layout = group.commandLayouts[layoutIdx];
                for (var command = 0; command < layout.commands.length; command++) {
                    visibleCommands = this.refreshCommand(layout.commands[command]) || visibleCommands;
                }
            }
            if (val(group.visible) === false || visibleCommands === false) {
                $groupNode.addClass('hidden');
                return false;
            } else {
                $groupNode.removeClass('hidden');
                return true;
            }
        }

    }
    loadPersistentNotifications() {
        try {
            this.persistentNotifications = JSON.parse(localStorage.notifications);
            if(this.persistentNotifications.length < 1) {
                this.persistentNotifications = [];
            } else {
                this.persistentNotifications = this.persistentNotifications.map(item => {
                    item.date = new Date(item.date);
                    return item;
                });
            }
        } catch (e) {
            this.persistentNotifications = [];
        }
    }

    addNotification(notification) {
        //Validate a name and action are present
        if(!notification.title || !notification.action) {
            return;
        }

        //Validate that the notificaiton does not already exists
        if(notification.id) {
            var existing = this.persistentNotifications.find(item => item.id === notification.id);
            if(existing) {
                return;
            }
        }

        // Copy to not change the original object
        notification = {...notification};

        //Set the current date if not set
        if(!notification.date) {
            notification.date = new Date(Date.now());
        }

        // If the date was passed as an object, covert it to a string
        if(!notification.date.toISOString) {
            notification.date = new Date(notification.date);
        }

        // Mark is as never viewed
        notification.viewed = false;
        notification.deleted = false; // We are keeping the deleted notifications for a certain time because we want to be able to match them by id if they come back

        // Add the notification
        if(notification.id) {
            if(typeof(notification.action) === 'function') {
                return;
            }
            this.persistentNotifications.push(notification);
            this.persistentNotifications.sort((a,b) =>  (b.deleted ? 0 : 1) - (a.deleted ? 0 : 1) || (b.viewed ? 0 : 1) - (a.viewed ? 0 : 1) || b.date - a.date);
            if(this.persistentNotifications.length > options.notificationsLength) { //Ensure maximum size
                this.persistentNotifications.splice(options.notificationsLength);
            }
            localStorage.notifications = JSON.stringify(this.persistentNotifications.map(item => {
                item = {...item};
                item.date = item.date.toISOString();
                return item;
            }));

        } else {
            this.transientNotifications.push(notification);
            this.transientNotifications.sort((a,b) =>  (b.deleted ? 0 : 1) - (a.deleted ? 0 : 1) || (b.viewed ? 0 : 1) - (a.viewed ? 0 : 1) || b.date - a.date);
            if(this.transientNotifications.length > options.notificationsLength) { //Ensure maximum size
                this.transientNotifications.splice(options.notificationsLength);
            }
        }

        // Update UI
        this.refreshNotifications();

        return;

    }

    refreshNotifications() {
        var $notificationsArea = this.$ribbonBar.find('.notification-dropdown > .dropdown-content').empty();
        this.$ribbonBar.find('.notification-button > .badge').remove();
        var notifications = [...this.persistentNotifications, ...this.transientNotifications];
        notifications.sort((a,b) =>  (b.deleted ? 0 : 1) - (a.deleted ? 0 : 1) || (b.viewed ? 0 : 1) - (a.viewed ? 0 : 1) || b.date - a.date);
        notifications = notifications.filter(item => !item.deleted);
        if(notifications.length > options.notificationsLength) { //Ensure maximum size
            notifications.splice(options.notificationsLength);
        }
        if(notifications.length === 0) {
            $('<p></p>').appendTo($notificationsArea).text(options.emptyNotificationsMessage);
            this.$ribbonBar.find('.notification-button').addClass('empty');
        } else {
            var numberNew = 0;
            notifications.forEach((notification, index) => {
                if(index > 0) {
                    $('<hr/>').appendTo($notificationsArea);
                }
                var $notification = $('<div class="notification' + (notification.viewed ? ' viewed' : '') + '">' +
                        '<div class="close" aria-hidden="true">x</div><div class="title"/>' +
                        '<div class="date"/>' +
                    '</div>').appendTo($notificationsArea).click(() => {
                    if(typeof(notification.action) === 'function') {
                        notification.action();
                    } else {
                        window.open(notification.action, notification.id ? notification.id : "_blank");
                    }
                    notification.viewed = true;
                    if(notification.id) {
                        localStorage.notifications = JSON.stringify(this.persistentNotifications.map(item => {
                            item = {...item};
                            item.date = item.date.toISOString();
                            return item;
                        }));
                    }
                    this.refreshNotifications();
                });
                var $title = $notification.find('.title').text(notification.title);
                if(notification.content) {
                    $('<div class="content"></div>').insertAfter($title).text(notification.content);
                }
                $notification.find('.date').text(new Date(notification.date).toLocaleString());
                $notification.find('.close').click((event) => {
                    event.stopPropagation();
                    notification.deleted = true;
                    if(notification.id) {
                        localStorage.notifications = JSON.stringify(this.persistentNotifications.map(item => {
                            item = {...item};
                            item.date = item.date.toISOString();
                            return item;
                        }));
                    }
                    this.refreshNotifications();

                });

                if(!notification.viewed) {
                    numberNew++;
                }
            });
            if(numberNew > 0) {
                this.$ribbonBar.find('.notification-button').append('<span class="badge">' + numberNew + '</span>').removeClass('empty');
            } else {
                this.$ribbonBar.find('.notification-button').addClass('empty');
            }
        }
    }

    _getLabelContainer($template) {
        var $name = $template.find('.label-container');

        if ($name.length === 0) {
            $template.append(
                $('<div/>', { class: 'label-container' })
            );

            $name = $template.find('.label-container');
        }

        return $name;
    }

    _commandFactory(id) {
        var command = options.commands[id];
        if (!command) {
            return false;
        }

        // uses generic template for all commands
        var $template = $('<div/>', {
            class: 'command ' + command.type,
            'data-command': id,
            html: $('<div/>', { class: 'image' })
        });

        // add label only if the command has a name
        if (command.name) {
            var $label = $('<span/>', { class: 'command-label' });
            // add labelClass as a class
            if (command.labelClass) {
                $label.addClass(command.labelClass);
            }
            this._getLabelContainer($template).append(
                $label
            );
        }

        // add caret-down for dropdown commands
        if (command.type === 'dropdown' || command.type === 'color' || command.type === 'dynamic-dropdown') {
            this._getLabelContainer($template).append(
                ' ', $('<span/>', { class: 'caret-down' })
            );
        }

        if (command.type === 'input-dropdown') {
            $template.append(
                $('<div/>', {
                    class: 'input-dropdown-group',
                    html: [
                        $('<input/>', {
                            type: 'text'
                        }),
                        $('<button/>', {
                            type: 'button',
                            html: $('<span/>', { class: 'caret-down' })
                        })
                    ]
                })
            );

            // remove image in the input
            $template.find('.image').remove();

            // delegates focus state for the command wrapper
            $template.on('focusin', 'input, textarea', function() {
                $(this).parent().addClass('active');
            });

            $template.on('focusout', 'input, textarea', function() {
                $(this).parent().removeClass('active');
            });
        }

        if (command.type === 'file') {
            var $form = $('<form/>', { html: $('<input/>', { type: 'file' }) }).appendTo($template);
            if (command.accept) {
                $form.find('input').attr('accept', command.accept);
            }
        }

        if (command.type === 'color') {
            $template.find('.image').after(
                $('<div/>', { class: 'command-color' })
            );
        }

        if (command.type === 'text-input') {
            $template.prepend($('<input/>', {
                type: 'text'
            }).attr('autocomplete', 'off'));
        }

        // add subtype as a class (used for style only)
        if (command.subtype) {
            $template.addClass(command.subtype);
        }

        return $template;
    }

    refreshCommand(id) {
        var escapeHTML = function(content){
            var text = content;

            text = text.replace(/&/g, '&amp;');
            text = text.replace(/</g, '&lt;');
            text = text.replace(/>/g, '&gt;');
            text = text.replace(/"/g, '&quot;');

            return text;
        };

        var $command = this.$ribbonBar.find('.command[data-command="' + id + '"]');
        var command = options.commands[id];
        if ($command.length === 0 || !command) {
            return;
        }
        if (command.name) {
            var cmdLabel = val(command.name) || "";
            var $label = $command.find('.command-label');
            $label.html(escapeHTML(cmdLabel).replace(/\r?\n/g, '<br />'));
            if(command.type === 'text-input') {
                var $textInput = $command.find('input[type=text]');
                $textInput.attr('placeholder', cmdLabel);
            }
        }

        if (command.type === 'color') {
            $command.find('.command-color').css('background-color', val(command.color));
        }

        // refresh only the layout without calling callbacks
        if (command.type === 'input-dropdown') {
            var $input = $command.find('input');
            var $group = $input.parent();

            if (typeof command.choices === 'function') {
                this.refreshDropdownChoices(id);
            } else {
                this.refreshInput($input, command, false);
            }

            // clean the input if the command is not enabled
            if (val(command.enabled) === false) {
                $input.val('').removeData('input');

                $command.children().addClass('disabled');
                $group.children().prop('disabled', true);
            } else {
                $command.children().removeClass('disabled');
                $group.children().prop('disabled', false);
            }
        }

        if (command.type === 'dynamic-dropdown') {
            let hasChoices = command.choices() ? true : false;
            if (Array.isArray(command.choices())) {
                hasChoices = command.choices().length > 0;
            } else if (hasChoices) {
                hasChoices = Object.keys(command.choices()).length > 0;
            }
            if (!hasChoices) {
                $command.find(".caret-down").hide();
            } else {
                $command.find(".caret-down").show();
            }
            this.refreshDropdownChoices(id);
        }

        if (command.type === 'text-input') {
            var $tInput = $command.find('input');

            // clean the input if the command is not enabled
            if (val(command.enabled) === false) {
                $command.children().addClass('disabled');
                $tInput.prop('disabled', true);
            } else {
                $command.children().removeClass('disabled');
                $tInput.prop('disabled', false);
            }
        }


        $command.attr('title', val(command.description));
        if (command.imageURL) {
            $command.find('.image').css('background-image', 'url("' + command.imageURL + '")');
        }

        if (val(command.enabled) === false) {
            $command.addClass('disabled');
        } else {
            $command.removeClass('disabled');
        }

        if (command.type === 'toggle') {
            if (val(command.toggled)) {
                $command.addClass('toggled');
            } else {
                $command.removeClass('toggled');
            }
        }

        if (command.commands) {
            if (command.type === 'dropdown' || command.type === 'input-dropdown' || command.type === 'dynamic-dropdown') {
                for (var subCommandIdx = 0; subCommandIdx < command.commands.length; subCommandIdx++) {
                    this.refreshCommand(command.commands[subCommandIdx]);
                }
            }
        }

        var $parent = $command.parent();

        var hasVisibleChildren = function($parent) {
            return $parent.children().filter(function() {
                return this.style.display !== 'none';
            }).length !== 0;
        };

        if (val(command.visible) === false) {
            $command.addClass('hidden');

            if ($parent.hasClass("small")) {
                // We have at least one $command that has a parent with a small layout, let's see if we need to hide it
                $parent.each(function() {
                    var $this = $(this);
                    if ($this.hasClass("small") && $this.parent().hasClass("small-container") && !hasVisibleChildren($this)) {
                        $this.parent().addClass("hidden");
                    }
                });
            }

            return false;
        } else {
            $command.removeClass('hidden');
            if ($parent.hasClass("small") && $parent.parent().hasClass("small-container")) {
                $parent.parent().removeClass("hidden");
            }
            return true;
        }
    }

    refreshSolutionDropdown() {
        //Control the visibility of the solution list button and it's following divider
        if (options.solutionDropdown && options.solutionDropdown.length > 0) {
            $('.solution-dropdown').removeClass('hidden').next().show();
        } else {
            $('.solution-dropdown').addClass('hidden').next().hide();
        }
    }

    activateTab(tab) {

        if (typeof (tab) === 'object') {
            tab = tab.id;
        }

        if (!tab) {
            //Find the default tab
            var tabs = this.$ribbonBar.find('.tabs li');
            var that = this;
            tabs.each(function () {
                var id = $(this).data('tab');
                if (id && id !== 'file' && !that.$ribbonBar.find('.tabs .tab[data-tab="' + id + '"]').hasClass('hidden')) {
                    tab = id;
                    return false;
                }
            });
        }
        if (tab === 'file') {
            this.openFilePane();
        } else {
            this.$ribbonBar.find('.tabs li.active').removeClass('active');
            this.$ribbonBar.find('.tabs .tab[data-tab="' + tab + '"]').addClass('active');

            var ribbonWasVisible = this.$ribbonBar.find('.ribbon:visible').addClass('hidden').length > 0;
            this.$ribbonBar.find('.ribbon[data-tab="' + tab + '"]').removeClass('hidden');
            if (!ribbonWasVisible) {
                this.$ribbonBar.trigger('resize', this.$ribbonBar.outerHeight());
            }
        }
    }

    refreshDropdownChoices(commandId, choices){
        var that = this;
        var command = options.commands[commandId];

        if (choices) {
            command.choices = choices;
        }
        var choicesHTML = this._choicesFactory(commandId, typeof command.choices === 'function' ? command.choices() : command.choices);

        var $inputDropdownContainer =  this.$ribbonBar.find('.dynamic-dropdown-container[data-command="' + commandId + '"]');

        // Remove all the possible choices so the dropdown doesn't have duplicates
        $inputDropdownContainer.children().remove();

        for (var i = 0; i < choicesHTML.length; i++) {
            $inputDropdownContainer.append(choicesHTML[i]);
        }

        // Rebind click handlers on toggle commands
        $inputDropdownContainer.find('.command.toggle').click(function(event) {
            var $command = $(this);
            if ($command.hasClass('disabled')) {
                return;
            }
            var commandId = $command.data('command');
            var command = options.commands[commandId];
            if (command) {
                var newValue = val(command.toggled);
                if (typeof (command.toggled) === 'boolean' || typeof (command.toggled) === 'undefined') {
                    command.toggled = !command.toggled;
                }
                if (typeof (command.callback) === 'function') {
                    var args = [!newValue];
                    args.push(arguments);
                    command.callback.apply(this, args);
                }
                that.refreshCommand(commandId);
            }
        });

        this.refreshInput(this.$ribbonBar.find('.input-dropdown[data-command="' + commandId + '"] input'), command, false);
    }

    setSolutionDropdown(solutions) {
        options.solutionDropdown = solutions.sort(sortSolutions);
        this.refreshSolutionDropdown();
    }

    setCurrentFile(paths) {
        var $current = this.$ribbonBar.find('.current-file').empty();
        var first = true;
        for (var pathIdx = 0; pathIdx < paths.length; pathIdx++) {
            if (!first) {
                $current.append('<div class="path-separator"></div><div class="path-segment">' + paths[pathIdx] + '</div>');
            } else {
                $current.append('<div class="path-segment">' + paths[pathIdx] + '</div>');
            }
            first = false;
        }
    }

    openFilePane(commandToActivate, param) {
        if (this.$ribbonBar.find('#file-pane').offset().left !== 0) {
            this.$ribbonBar.find('#file-pane').css({ left: -$(window).width() }).animate({ left: 0 }, 100, () => {
                this.$ribbonBar.trigger('filepaneopen');
            });
        }
        if (commandToActivate) {
            this.$ribbonBar.find('#file-pane').find('.command[data-command="' + commandToActivate + '"]').trigger('click', param);
        }
    }

    closeFilePane() {
        this.$ribbonBar.find('#file-pane').animate({ left: -$(window).width() }, 100, () => {
            this.$ribbonBar.find('#file-pane').css('left', '');
            this.$ribbonBar.find('.file-pane-right-dropdown').hide();
            this.$ribbonBar.find('#file-pane .command').removeClass('active');
            this.$ribbonBar.trigger('filepaneclose');
        });
    }

    /**
     * Refreshes input on change
     * @param {HTML} $input - The input which has changes.
     * @param {object} command - The executed command when the input changes.
     * @param {boolean} callCallback - Flag used to call the command callback.
     */
    refreshInput($input, command, callCallback) {
        if (!command.callback || typeof command.callback !== 'function') {
            return;
        }

        var inputConfig = null; // set of allowed values
        var inputData = null; // new value of the input
        var displayOnlyConfigValues = true;

        // each specific input needs to implement their specific input checker by setting inputData and inputConfig values
        switch (command.subtype) {
            case 'font-size':
                var fontSizeValue = Number($input.val()) || 0;
                var fontSizeRange = [];

                inputConfig = fontChoices['font-size'];
                for (var i = fontSizeBounds.min; i <= fontSizeBounds.max; i++) {
                    fontSizeRange.push(i);
                    inputConfig[i + 'px'] = i;
                }

                if (!callCallback || (fontSizeRange.indexOf(fontSizeValue) === -1 && command.input)) {
                    inputData = command.input() || '';
                } else {
                    inputData = fontSizeValue + 'px';
                }

                break;
            case 'font-family':
                var fontFamilyValue = $input.val() || '';
                inputConfig = fontChoices['font-family'];
                var fontInputKey = null;

                if (fontFamilyValue !== '') {
                    for (var fontKey in inputConfig) {
                        // ignores all backup fonts from font-family keys in order to match the input font
                        if (fontKey.split(',')[0].indexOf(fontFamilyValue.toLowerCase()) !== -1) {
                            fontInputKey = fontKey;
                            break;
                        }
                    }
                }

                if (!callCallback || (!fontInputKey && command.input)) {
                    var fontInput = command.input() || '';

                    // sanitizes all entry values
                    inputData = fontInput.replace(/["]/g, '').replace(/, /g, ',').trim();

                    if (!inputConfig[inputData]) {
                        displayOnlyConfigValues = false;
                    }
                } else {
                    inputData = fontInputKey;
                }

                break;
            default:
                if (callCallback) {
                    command.callback.apply(this, [$input.data('value') || '']);
                    return;
                }
                if (typeof command.input === 'function') {
                    inputConfig = typeof command.choices === 'function' ? command.choices() : command.choices;
                    inputData = command.input();
                }
        }

        // allow input modification when its value exists or when it is an empty value
        if (inputConfig && (inputData || inputData === '')) {
            // change the input data and call the command callback
            // only if the data is dirty and if the input has been explicitly changed
            if (callCallback && inputData) {
                command.callback.apply(this, [inputData]);
            }

            if (displayOnlyConfigValues) {
                if (Array.isArray(inputConfig)) {
                    if (inputConfig.indexOf(inputData) !== -1) {
                        $input.val(inputData);
                    } else {
                        $input.val('');
                    }
                } else {
                    $input.val(inputConfig[inputData] || '');
                }
            } else {
                $input.val(inputData);
            }
        }
    }

    /**
     * Creates an array of commands from an array or a map of choices
     * @param {string} commandId - The id of the master command which displays the choice.
     * @param {array|object} choices - The choices as an array or a key-value object which will be converted to commands
     * @return {HTML[]} An array of HTML commands which represents the choices.
     */
    _choicesFactory(commandId, choices) {
        var choicesHTML = [];

        if (Array.isArray(choices)) {
            for (var choiceIdx = 0; choiceIdx < choices.length; choiceIdx++) {
                choicesHTML.push(this._choiceFactory(commandId, choices[choiceIdx]));
            }
        } else if (choices && typeof choices === 'object') {
            for (var key in choices) {
                choicesHTML.push(this._choiceFactory(commandId, key, choices[key]));
            }
        }

        return choicesHTML;
    }

    /**
     * Creates a command from a simple choice value (used for dropdown)
     * @param {string} commandId - The id of the master command which displays the choice.
     * @param {string} choiceValue - The value which will be used in the command callback.
     * @param {string|number} [choiceLabel=choiceValue] - The value which will be displayed in the dropdown.
     * @return {HTML} The HTML command which represents the choice.
     */
    _choiceFactory(commandId, choiceValue, choiceLabel) {
        var command = options.commands[commandId];

        if (!command || !choiceValue) {
            return;
        } else if (!choiceLabel) {
            choiceLabel = choiceValue;
        }

        var choiceId = commandId + '-' + String(choiceValue).toLowerCase().replace(' ', '-');
        options.commands[choiceId] = {
            type: 'toggle',
            name: String(choiceLabel),
            toggled: function(commandId, value) {
                if (command.type !== "dynamic-dropdown") {
                    return $('[data-command="' + commandId + '"]').find('input').val() === String(value);
                }
                return false;
            }.bind(this, commandId, choiceLabel),
            callback: function(commandId, label) {
                if (command.type === "dynamic-dropdown") {
                    command.callback(choiceValue);
                } else {
                    var $input = $('[data-command="' + commandId + '"]').find('input');
                    $input.data('value', choiceValue);
                    $input.val(choiceLabel).change();
                }
            }.bind(this, commandId, choiceLabel)
        };

        if (!command.commands) {
            command.commands = [];
        }
        if (!command.commands.includes(choiceId)) {
            command.commands.push(choiceId);
        }
        var $choice = this._commandFactory(choiceId);

        // add style for font-family components
        if (command.subtype === 'font-family') {
            $choice.find('.command-label').css({
                'font-family': choiceValue
            });
        }

        // also remove image for each choice component
        $choice.find('.image').remove();

        return $choice;
    }

    /**
     * Gets the corresponding layout HTML due to the layout configuration and the command order.
     * @param {object} layout - The layout configuration.
     * @param {HTML} $group - The container of the layout.
     * @param {number} commandIndex - The index of the command to place in the layout.
     * @return {HTML} The HTML layout which will contain the processed command.
     */
    _getLayoutContainer(layout, $group, commandIndex) {
        if (layout.orientation === 'horizontal') {
            this._$currentLayout = $('<div class="layout ' + layout.type + '"></div>').appendTo($group);

            if (layout.type === 'small') {
                var $container = $('<span class="layout small-row-container"></span>').appendTo($group);
                $container.append(this._$currentLayout);
            }
        } else { // default orientation behaviour (two small command by columns)
            var $parent = $group;

            if (commandIndex === 0 || layout.type !== 'small') {
                this._smallLayoutCommandsCount = 0;
            }

            if (layout.type === 'small') {
                if (this._smallLayoutCommandsCount % 2 === 0) {
                    $container = $('<div class="layout small-container"></div>');

                    $group.append($container);
                    $parent = $container;

                    if (commandIndex > 0) {
                        this._$currentLayout = $('<div class="layout ' + layout.type + '"></div>').appendTo($parent);
                    }
                } else {
                    $parent = $group.find(".layout.small-container").last();
                }

                this._smallLayoutCommandsCount++;
            }

            if (commandIndex === 0) {
                this._$currentLayout = $('<div class="layout' + (layout.type ? ' ' + layout.type: '') + '"></div>').appendTo($parent);
            }
        }

        return this._$currentLayout;
    }

}

// jQuery Wrapper
jQuery.fn.ribbonBar = function (method, ...args) {
    if (this.length > 1) {
        $.error('Your selector should return a single ribbon-bar : ' + this.length);
        return this;
    } else if (this.length === 0) {
        $.error('Your selector should return a ribbon-bar');
        return this;
    } else {
        var data = this.data('ribbon-bar');
        if (!data || typeof method === 'object') {
            this.data('ribbon-bar', (data = new Ribbon(this[0], method || {})));
        }

        if (data && typeof method === 'string' && typeof data[method] === 'function') {
            var returnValue = data[method].apply(data, args);
            if (typeof returnValue !== 'undefined') {
                return returnValue;
            }
        } else if (!data) {
            $.error('Method ' + method + ' does not exist on jQuery.$ribbonBar');
        }
        return this;
    }
};
